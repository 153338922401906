import React from "react"
import { rewriteCollageScreenshot, rewriteToCollageModule } from "./rewrites"
import dynamic from "next/dynamic"

import { Module as ModuleWrapper } from "src/components/module/Module"
import CardStylesModule from "src/modules/card-styles-module/CardStylesModule"
import HeadlineTextAndCta from "src/modules/headline-text-and-cta/HeadlineTextAndCta"
import CampaignModule from "src/modules/campaign-module/CampaignModule"
import CollageModule from "src/modules/collage-module/CollageModule"
// import CollageDefaultModule from "src/modules/collage-default-module/CollageDefaultModule"
// import CollageScreenshotLargeModule from "src/modules/collage-screenshot-large-module/CollageScreenshotLargeModule"
// import FaqModule from "src/modules/faq-module/FaqModule"
import FeaturesGroupModule from "src/modules/features-group-module/FeaturesGroupModule"
import FeatureWalkthroughModule from "src/modules/feature-walkthrough-module/FeatureWalkthroughModule"
import FullscreenImageModule from "src/modules/fullscreen-image-module/FullscreenImageModule"
import HeroBasicModule from "src/modules/hero-basic-module/HeroBasicModule"
import HeroBlogModule from "src/modules/hero-blog-module/HeroBlogModule"
// Deprecated (Migrated to CollageModule)
// import HeroCollageModule from "src/modules/hero-collage-module/HeroCollageModule"

import HeroParallaxModule from "src/modules/hero-parallax-module/HeroParallaxModule"
import HeroSubscriptionsModule from "src/modules/hero-subscriptions-module/HeroSubscriptionsModule"
import ImageModule from "src/modules/image-module/ImageModule"
import LoanModule from "src/modules/loan-module/LoanModule"
import KeyNumbersModule from "src/modules/key-numbers-module/KeyNumbersModule"
import LeadParagraphModule from "src/modules/lead-paragraph-module/LeadParagraphModule"
import ProductListModule from "src/modules/product-list-module/ProductListModule"
// import ProductOverviewModule from "src/modules/product-overview-module/ProductOverviewModule"
import QAListModule from "src/modules/qa-list-module/QAListModule"
import RibbonModule from "src/modules/ribbon-module/RibbonModule"
import RichTextModule from "src/modules/rich-text-module/RichTextModule"
import StatementsCircleModule from "src/modules/statements-circle-module/StatementsCircleModule"
import StatementsModule from "src/modules/statements-module/StatementsModule"
import StepsModule from "src/modules/steps-module/StepsModule"
// import SubscriptionsListModule from "src/modules/subscriptions-list-module/SubscriptionsListModule"
// import SubscriptionTableModule from "src/modules/subscription-table-module/SubscriptionTableModule"
// import TestimonialsModule from "src/modules/testimonials-module/TestimonialsModule"
import TextGridModule from "src/modules/text-grid-module/TextGridModule"
import CustomComponentModule from "src/modules/custom-component-module/CustomComponent"
// import BlogPostGridModule from "src/modules/blog-post-grid-module/BlogPostGridModule"
// import UspModule from "src/modules/usp-module/UspModule"
// import WillPowerQuoteModule from "src/modules/will-power-quote-module/WillPowerQuoteModule"
// import RateTableModule from "src/modules/rate-table-module/RateTableModule"
import TableModule from "src/modules/table-module/TableModule"

const VideoModule = dynamic(() => import("src/modules/video-module/VideoModule"))
const TypeFormModule = dynamic(() => import("src/modules/typeform-module/TypeFormModule"))
const InvestmentModule = dynamic(() => import("src/modules/investment-module/InvestmentModule"))
const WillPowerQuoteModule = dynamic(() => import("src/modules/will-power-quote-module/WillPowerQuoteModule"))
const RateTableModule = dynamic(() => import("src/modules/rate-table-module/RateTableModule"))
const UspModule = dynamic(() => import("src/modules/usp-module/UspModule"))
const TestimonialsModule = dynamic(() => import("src/modules/testimonials-module/TestimonialsModule"))
const BlogPostGridModule = dynamic(() => import("src/modules/blog-post-grid-module/BlogPostGridModule"))
const SubscriptionTableModule = dynamic(() => import("src/modules/subscription-table-module/SubscriptionTableModule"))
const SubscriptionsListModule = dynamic(() => import("src/modules/subscriptions-list-module/SubscriptionsListModule"))
const CollageScreenshotLargeModule = dynamic(() => import("src/modules/collage-screenshot-large-module/CollageScreenshotLargeModule"))
const ProductOverviewModule = dynamic(() => import("src/modules/product-overview-module/ProductOverviewModule"))
const CurrencyConverterModule = dynamic(() => import("src/modules/currency-converter-module/CurrencyConverterModule"))
// import VideoModule from "src/modules/video-module/VideoModule"
// import TypeFormModule from "src/modules/typeform-module/TypeFormModule"
// import InvestmentModule from "src/modules/investment-module/InvestmentModule"


import { BaseModule, ModuleType, PageThemeType } from "../../sanity/types"
import CurrencyTableModule from "src/modules/currency-table-module/CurrencyTableModule"

export default function ModuleList({ modules = [], pageThemeType, pageSlug, updatedAt }: { modules: BaseModule[], pageThemeType?: PageThemeType, pageSlug?: string, updatedAt?: string }): JSX.Element {
    if (!modules || !modules.length) {
        return null
    }

    return (
        <>
            {modules.map((props, moduleIndex) => {
                const key = `${[props.id, moduleIndex, pageSlug, updatedAt ?? ""].join("-")}`
                const Module = getModuleFromModuleName(props.type, { ...props, moduleIndex }, pageThemeType)
                return (
                    <ModuleWrapper moduleType={props.type} collapseMarginTop={props.removeMarginTop} collapseMarginBottom={props.removeMarginBottom} key={key} removeMargin={props.removeMargin} id={"module-" + (moduleIndex + 1)}>
                        {Module}
                    </ModuleWrapper>
                )
            })}
        </>
    )
}

function getModuleFromModuleName(moduleType: ModuleType, props: any, pageThemeType) {
    // Rewritten modules
    const rewrittenModules = [ModuleType.HERO_FULL_IMAGE, ModuleType.HERO_COLLAGE, ModuleType.COLLAGE_DEFAULT, ModuleType.COLLAGE_SCREENSHOT_LARGE, ModuleType.HERO_BASIC]
    if (rewrittenModules.includes(moduleType))
        return <CollageModule {...rewriteToCollageModule(props, moduleType)} />
    if (moduleType === ModuleType.COLLAGE_SCREENSHOT)
        return <CollageModule {...rewriteToCollageModule(rewriteCollageScreenshot(props), ModuleType.COLLAGE_DEFAULT)} />
    switch (moduleType) {
        case ModuleType.CUSTOM_COMPONENT:
            return <CustomComponentModule {...props} />
        case ModuleType.CURRENCY_CONVERTER:
            return <CurrencyConverterModule {...props} />
        case ModuleType.CURRENCY_TABLE:
            return <CurrencyTableModule {...props} />
        case ModuleType.FULLSCREEN_IMAGE:
            return <FullscreenImageModule {...props} />
        case ModuleType.HERO_BASIC:
            return <HeroBasicModule {...props} />
        case ModuleType.USP:
            return <UspModule {...props} />
        case ModuleType.LEAD_PARAGRAPH:
            return <LeadParagraphModule {...props} />
        case ModuleType.RICH_TEXT:
            return <RichTextModule {...props} />
        case ModuleType.RIBBON:
            return <RibbonModule {...props} />
        case ModuleType.TESTIMONIALS:
            return <TestimonialsModule {...props} />
        case ModuleType.KEY_NUMBERS:
            return <KeyNumbersModule {...props} />
        case ModuleType.TEXT_GRID:
            return <TextGridModule {...props} pageThemeType={pageThemeType} />
        case ModuleType.BLOG_POST_GRID:
            return <BlogPostGridModule {...props} pageThemeType={pageThemeType} />
        case ModuleType.QA_LIST:
            return <QAListModule {...props} pageThemeType={pageThemeType} />
        case ModuleType.STEPS:
            return <StepsModule {...props} pageThemeType={pageThemeType} />
        case ModuleType.COLLAGE:
            return <CollageModule {...props} />
        case ModuleType.HEADLINE_TEXT_AND_CTAA:
            return <HeadlineTextAndCta {...props} />
        case ModuleType.CAMPAIGN:
            return <CampaignModule {...props} />
        case ModuleType.COLLAGE_SCREENSHOT_LARGE:
            return <CollageScreenshotLargeModule {...props} />
        case ModuleType.PRODUCT_LIST:
            return <ProductListModule  {...props} />
        case ModuleType.PRODUCT_OVERVIEW:
            return <ProductOverviewModule  {...props} />
        case ModuleType.HERO_SUBSCRIPTIONS:
            return <HeroSubscriptionsModule {...props} />
        case ModuleType.SUBSCRIPTION_TABLE:
            return <SubscriptionTableModule {...props} />
        case ModuleType.FEATURES_GROUP:
            return <FeaturesGroupModule {...props} />
        case ModuleType.HERO_PARALLAX:
            return <HeroParallaxModule {...props} />
        case ModuleType.STATEMENTS:
            return <StatementsModule {...props} />
        case ModuleType.STATEMENTS_CIRCLE:
            return <StatementsCircleModule {...props} />
        case ModuleType.CARD_STYLES:
            return <CardStylesModule {...props} />
        case ModuleType.VIDEO:
            return <VideoModule {...props} />
        case ModuleType.FEATURE_WALKTHROUGH:
            return <FeatureWalkthroughModule {...props} />
        case ModuleType.SUBSCRIPTIONS_LIST:
            return <SubscriptionsListModule {...props} />
        case ModuleType.IMAGE:
            return <ImageModule {...props} />
        case ModuleType.LOAN:
            return <LoanModule {...props} />
        case ModuleType.HERO_BLOG:
            return <HeroBlogModule {...props} />
        case ModuleType.WILLPOWERQUOTE:
            return <WillPowerQuoteModule {...props} />
        case ModuleType.INVESTMENT:
            return <InvestmentModule {...props} />
        case ModuleType.TYPEFORM:
            return <TypeFormModule {...props} />
        case ModuleType.RATE_TABLE:
            return <RateTableModule {...props} />
        case ModuleType.TABLE:
            return <TableModule {...props} />
    }
}
