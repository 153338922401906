import React from "react"
import { Container } from "src/layout/container/Container"
import cn from "classnames"
import { PageThemeType, SanityCallToAction, SanityRichText } from "src/sanity/types"
import s from "./QAListModule.module.scss"
import { Section, Heading } from "src/utils"
import Accordion from "../../components/accordion/Accordion"
import Panel from "../../components/accordion/Panel"
import RichText from "../../components/rich-text/RichText"
import { richTextToString } from "src/utils"
import ButtonGroup from "src/components/button-group/ButtonGroup"
import CallToAction from "src/components/call-to-action/CallToAction"

interface TextItem {
    id: string
    text: SanityRichText
    title: string
}

interface QAListModuleProps {
    id: string
    title: string
    bodyText?: string
    cta?: SanityCallToAction
    secondaryCta?: SanityCallToAction
    list: TextItem[]
    pageThemeType?: PageThemeType
}

export default function QAListModule({ title, bodyText, cta, secondaryCta, list, pageThemeType }: QAListModuleProps): JSX.Element {

    return (
        <Section>
            <div className={cn(s["qa-list-module"])}>
                <Container className={cn(s["qa-list-module__wrapper"])}>
                    <div className={cn(s["qa-list-module__left-container"])}>
                        <Heading className={cn(s["qa-list-module__title"], "heading-200")}>
                            {title}
                        </Heading>

                        {/* Bodytext  */}
                        {bodyText && (
                            <p className={cn(s["qa-list-module__body-text"])}>{bodyText}</p>
                        )}

                        {/* CTA */}
                        {cta?.style && (
                            <ButtonGroup className={cn(s["qa-list-module__cta-group"])}>
                                <CallToAction {...cta} className={cn(s["qa-list-module__cta"])} />
                                {secondaryCta?.style && <CallToAction {...secondaryCta} appearance="secondary" className={cn(s["qa-list-module__cta"], s["qa-list-module__cta--secondary"])} />}
                            </ButtonGroup>
                        )}
                    </div>
                    {list?.length > 0 && (
                        <Accordion className={s["qa-list-module__accordion"]}>
                            {list.map((i, index) => {
                                return (
                                    <Panel
                                        id={i.id}
                                        title={i.title}
                                        key={index}
                                        index={index}
                                        pageThemeType={pageThemeType}
                                    >
                                        <RichText blocks={i.text} className="paragraph-100" />
                                    </Panel>
                                )
                            })}
                        </Accordion>
                    )}
                </Container>
            </div>
            <script type='application/ld+json'
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": list?.map(question => ({
                            "@type": "Question",
                            "name": question.title,
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": richTextToString(question.text),
                            },
                        })),
                    }),
                }} >
            </script>
        </Section>
    )
}




