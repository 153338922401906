import React, { useEffect, useRef, useState } from "react"
import { Section } from "src/utils"
import s from "./Panel.module.scss"
import cn from "classnames"
import Icon, { IconType } from "src/components/icon/Icon"
import { PageThemeType } from "src/sanity/types"


interface PaneProps {
    title: string
    activeTab?: number
    index?: number
    id: string
    fill?: boolean
    activateTab?: (index: number) => void
    children?: React.ReactNode
    pageThemeType?: PageThemeType
}

export default function Pane({ id, title, fill, children, activeTab, index, activateTab, pageThemeType }: PaneProps): JSX.Element {
    const [height, setHeight] = useState<string | number>(0)
    const ref = useRef<HTMLDivElement>(null)
    const isActive = activeTab === index
    const [hidden, setHidden] = useState(true)

    useEffect(() => {
        if (isActive) {
            setHeight(ref.current.clientHeight)
            const tid = setTimeout(() => setHeight("auto"), 300)

            return () => {
                clearTimeout(tid)
            }
        } else {
            setHeight(ref.current.clientHeight)
            const tid = setTimeout(() => setHeight(0), 20)

            return () => {
                clearTimeout(tid)
            }
        }
    }, [isActive])

    useEffect(() => {
        if (isActive) {
            setHidden(false)
        } else {
            const tid = setTimeout(() => setHidden(true), 300)

            return () => {
                clearTimeout(tid)
            }
        }
    }, [isActive])

    return (
        <Section>
            <div
                className={cn(s["panel"], pageThemeType === "dark" ? s["panel--dark-theme"] : "", { [s["panel--is-active"]]: isActive })}
            >
                <div
                    className={cn(s["panel__tab"])}
                    role='tab'
                    aria-selected={isActive}
                    aria-controls={`t-${id}`}
                    id={`th-${id}`}
                    tabIndex={0}
                    onClick={() => activateTab(index)}
                    onKeyDown={(e) => {
                        if (e.key === " " || e.key === "Enter") {
                            e.preventDefault()
                            activateTab(index)
                        }
                    }}
                >
                    <h3 className={cn(s["panel__tab__title"], "label-200")}>
                        {title}
                    </h3>
                    <div
                        className={cn(s["panel__tab__icon"], {
                            [s["panel__tab__icon--is-active"]]: isActive,
                        })} >
                        <Icon type={IconType.CHEVRON_DOWN} />
                    </div>
                </div>
                <div
                    className={s["panel__inner"]}
                    role='tabpanel'
                    aria-labelledby={`th-${id}`}
                    id={`t-${id}`}
                    style={{
                        height,
                        visibility: hidden ? "hidden" : "visible",
                    }}
                    aria-hidden={!isActive}
                >
                    <div
                        ref={ref}
                        className={cn(s["panel__content"], {
                            [s["panel__content--is-active"]]: isActive,
                            [s["panel__content--fill"]]: fill,
                        })}
                    >
                        {children}
                    </div>
                </div>
            </div>
        </Section>
    )
}
