import { clamp, Heading, Section, useAnimationFrame, useIsomorphicLayoutEffect } from "src/utils"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { Container } from "src/layout/container/Container"
import cn from "classnames"
import s from "./FeaturesGroupModule.module.scss"
import Angle from "src/components/angle/Angle"
import { HorizontalPosition, SanityImage, SanityRichText, ThemeColor } from "../../sanity/types"
import RichText from "src/components/rich-text/RichText"
import Media from "src/components/media/Media"

interface Feature {
    title: string
    shapeColor: ThemeColor
    text: SanityRichText
    id: string
    midImagePosition: HorizontalPosition
    frontImage: SanityImage
    midImage: SanityImage
    frontImagePosition: HorizontalPosition
    shapePosition: HorizontalPosition
}

interface FeaturesGroupModuleProps {
    title: string
    textPosition: HorizontalPosition
    features: Feature[]
}

export default function FeaturesGroupModule({
    title,
    features = [],
    textPosition,
}: FeaturesGroupModuleProps): JSX.Element {
    const [activeFeature, setActiveFeature] = useState(0)
    const refInner = useRef<HTMLDivElement>()
    const refOuter = useRef<HTMLDivElement>()
    const refStickyWrapper = useRef<HTMLDivElement>()
    const currentOpacity = useRef<number>(0)
    const targetOpacity = useRef<number>(0)
    const refWrapper = useRef<HTMLDivElement>()
    const [top, setTop] = useState(0)
    const calcTop = useCallback(() => {
        if (!refInner.current) {
            return
        }

        const { height: outerHeight } = refInner.current.getBoundingClientRect()

        setTop((window.innerHeight - outerHeight) / 2)
    }, [])

    useEffect(() => {
        let tid: NodeJS.Timeout
        const onResize = () => {
            clearTimeout(tid)
            tid = setTimeout(() => calcTop(), 100)
        }

        window.addEventListener("resize", onResize)

        return () => {
            window.removeEventListener("resize", onResize)

        }
    }, [calcTop])

    useIsomorphicLayoutEffect(() => {
        calcTop()
    }, [calcTop, activeFeature])

    useEffect(() => {
        const onScroll = () => {
            if (window.matchMedia("(max-width: 1100px)").matches || !refOuter.current) {
                return
            }

            const { top, height, bottom } = refOuter.current.getBoundingClientRect()
            const index = clamp(Math.floor((-top + window.innerHeight / 2) / (height) * (features.length)), 0, features.length - 1)
            const opacityTop = clamp((-top + window.innerHeight * .35) / (window.innerHeight * .1), 0, 1)
            const opacityBottom = clamp((-bottom + window.innerHeight) / (window.innerHeight * .1), 0, 1)

            targetOpacity.current = opacityTop - opacityBottom

            setActiveFeature(index)
        }

        window.addEventListener("scroll", onScroll)

        return () => {
            window.removeEventListener("scroll", onScroll)
        }
    }, [features])

    useAnimationFrame(() => {
        if (!refStickyWrapper.current) {
            return
        }

        currentOpacity.current += (targetOpacity.current - currentOpacity.current) * .5
        refStickyWrapper.current.style.opacity = currentOpacity.current.toFixed(2)
    })

    return (
        <Section>
            <div
                className={cn(s["features-group-module"])}
                ref={refWrapper}
            >
                <Heading className={cn(s["features-group-module__title"], "heading-300")}>
                    {title}
                </Heading>

                <div
                    className={cn(s["features-group-module__inner"])}
                    ref={refOuter}
                >
                    <div
                        className={cn(s["features-group-module__sticky-wrapper"])}
                        ref={refStickyWrapper}
                    >
                        <div
                            style={{ top }}
                            className={cn(s["features-group-module__sticky-wrapper__inner"])}
                            ref={refInner}
                        >
                            <Container>
                                <div
                                    className={cn(
                                        s["features-group-module__feature-outer"],
                                        s["features-group-module__feature-outer--" + textPosition],
                                    )}
                                >
                                    <div
                                        className={cn(
                                            s["features-group-module__feature-outer__list"],
                                            s["features-group-module__feature-outer__list--top"],
                                        )}
                                    >
                                        {generateFeaturesList(features.slice(0, activeFeature), "top")}
                                    </div>
                                    <div
                                        className={cn(
                                            s["features-group-module__feature"],
                                            s["features-group-module__feature--active"],
                                        )}
                                        key={Math.random()}
                                    >
                                        <Section>
                                            <Heading className={cn(s["feature__content__title"], "heading-100")}>{features[activeFeature].title}</Heading>
                                            <RichText blocks={features[activeFeature].text} className="paragraph-100" />
                                        </Section>
                                    </div>
                                    <div
                                        className={cn(
                                            s["features-group-module__feature-outer__list"],
                                            s["features-group-module__feature-outer__list--bottom"],
                                        )}
                                    >
                                        {generateFeaturesList(features.slice(activeFeature + 1), "bottom")}
                                    </div>
                                </div>
                            </Container>
                        </div>
                    </div>

                    <Container>
                        {features.map(i => {
                            return (
                                <Feature key={i.id} feature={i} textPosition={textPosition} />
                            )
                        })}
                    </Container>
                </div>
            </div>
        </Section>
    )
}

function generateFeaturesList(features: Feature[], position: "top" | "bottom") {
    return features.map((i, index) => {
        return (
            <div
                key={i.id}
                style={{
                    animationDelay: (position === "top" ? features.length - index : index + 1) * .05 + "s",
                }}
                className={cn(
                    "paragraph-100",
                    s["features-group-module__feature"],
                    s["features-group-module__feature--inactive"],
                )}
            >
                {i.title}
            </div>
        )
    })
}

function Feature({
    feature,
    textPosition,
}: { feature: Feature, textPosition: HorizontalPosition }) {
    const [visible, setVisible] = useState(false)
    const wrapperRef = useRef<HTMLDivElement>()
    const shapeRef = useRef<HTMLDivElement>()
    const scrollY = useRef<number>(0)
    const currentY = useRef<number>(0)
    const scale = useRef<number>(0)

    useEffect(() => {
        scale.current = Math.random() * .5 + .35
    }, [])

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setVisible(entry.isIntersecting)
        }, { threshold: [0], rootMargin: "200px 0px 0px 0px" })

        observer.observe(wrapperRef.current)

        return () => {
            observer.disconnect()
        }
    }, [])

    useAnimationFrame(() => {
        if (!visible) {
            return
        }

        currentY.current += (scrollY.current - currentY.current) * .2

        shapeRef.current.style.transform = `translate3d(0, ${(scale.current * currentY.current * window.innerHeight * .5).toFixed(2)}px, 0)`
    }, [visible])

    useEffect(() => {
        const onScroll = () => {
            const { top, height } = wrapperRef.current.getBoundingClientRect()
            const y = (-top + window.innerHeight / 2) / height / 2

            scrollY.current = clamp(y, -.5, 1)
        }

        onScroll()

        window.addEventListener("scroll", onScroll)

        return () => {
            window.removeEventListener("scroll", onScroll)
        }
    }, [])

    return (
        <div ref={wrapperRef} className={cn(s["feature"])}>
            <div
                className={cn(
                    s["feature__content--" + textPosition],
                    s["feature__content"],
                )}
            >
                <Section>
                    <Heading className={cn(s["feature__content__title"], "heading-100")}>{feature.title}</Heading>
                    <RichText blocks={feature.text} className="paragraph-100" />
                </Section>
            </div>
            <div
                className={cn(
                    s["feature__images"],
                    {
                        [s["feature__images--right"]]: textPosition === "left",
                        [s["feature__images--left"]]: textPosition === "right",
                    },
                )}
            >
                <div
                    ref={shapeRef}
                    className={cn(
                        `text-${feature.shapeColor}`,
                        s["feature__images__back"],
                        s["feature__images__back--" + feature.shapePosition],
                    )}
                >
                    <Angle height={70} color="currentColor" />
                </div>
                <div
                    className={cn(
                        s["feature__images__mid"],
                        s["feature__images__mid--" + feature.midImagePosition],
                    )}
                >
                    <Media
                        media={feature.midImage}
                        {...feature.midImage}
                        width={440}
                        height={586}
                        sizes={["(max-width: 1400px) 45vw, 575px"]}
                        alt=""
                    />
                </div>
                <div
                    className={cn(
                        s["feature__images__front"],
                        s["feature__images__front--" + feature.frontImagePosition],
                    )}
                >
                    <img
                        src={`${feature.frontImage.src}?w=650&auto=format`}
                        alt=""
                        loading="lazy"
                    />
                </div>
            </div>
        </div>
    )
}
