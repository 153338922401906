import { clamp, Heading, useAnimationFrame } from "src/utils"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { Container } from "src/layout/container/Container"
import cn from "classnames"
import s from "./HeroParallaxModule.module.scss"
import type { HeroStyle, SanityRichText, ThemeColor } from "src/sanity/types"
import FullscreenMedia from "src/components/fullscreen-media/FullscreenMedia"
import RichText from "src/components/rich-text/RichText"
import { DefaultHeroMediaProps } from "../hero-base/types"

interface HeroParallaxModuleProps extends DefaultHeroMediaProps {
    title: SanityRichText
    heroStyle: HeroStyle
    titlePosition: "left" | "center"
    titleSize: "large" | "extraLarge"
    mediaBackgroundColor: ThemeColor
}

export default function HeroParallaxModule({
    title,
    media,
    titlePosition,
    titleSize,
    heroStyle,
    mediaBackgroundColor,
}: HeroParallaxModuleProps): JSX.Element {
    const size = 100
    const [height, setHeight] = useState(`${size}vh`)
    const [bottomAnglePosition, setBottomAnglePosition] = useState("91%")
    const outerRef = useRef<HTMLDivElement>()
    const targetOpacity = useRef<number>(1)
    const currentOpacity = useRef<number>(1)
    const targetY = useRef<number>(0)
    const currentY = useRef<number>(0)
    const titleTopRef = useRef<HTMLDivElement>()
    const titleBottomRef = useRef<HTMLDivElement>()
    const overflowRef = useRef<HTMLDivElement>()
    const calcAngle = useCallback((width, height): string => {
        return (height - Math.round(width * Math.tan(5 * Math.PI / 180))) + "px"
    }, [])

    useEffect(() => {
        const isSmall = window.matchMedia("(max-width: 600px)").matches
        const height = window.innerHeight * ((size - (isSmall ? 15 : 0)) / 100)
        const { width } = overflowRef.current.getBoundingClientRect()

        setHeight(height + "px")
        setBottomAnglePosition(calcAngle(width, height))
    }, [calcAngle])

    useEffect(() => {
        let tid: ReturnType<typeof setTimeout>
        const onResize = () => {
            clearTimeout(tid)
            tid = setTimeout(() => {
                const isSmall = window.matchMedia("(max-width: 600px)").matches
                const { width } = overflowRef.current.getBoundingClientRect()
                const height = window.innerHeight * ((size - (isSmall ? 15 : 0)) / 100)

                setBottomAnglePosition(calcAngle(width, height))
            }, 100)
        }

        window.addEventListener("resize", onResize)

        return () => {
            window.removeEventListener("resize", onResize)
        }
    }, [calcAngle])

    useAnimationFrame(() => {
        if (!titleTopRef.current || !titleBottomRef.current || window.matchMedia("(max-width: 500px)").matches) {
            return
        }

        currentOpacity.current += (targetOpacity.current - currentOpacity.current) * .5
        currentY.current += (targetY.current - currentY.current) * .25

        titleTopRef.current.style.transform = `translate3d(0, calc(${currentY.current.toFixed(0)}px), 0)`
        titleBottomRef.current.style.transform = `translate3d(0, calc(-100% + ${currentY.current.toFixed(0)}px - var(--title-bottom) ), 0)`
        titleBottomRef.current.style.opacity = currentOpacity.current.toFixed(2)
    })

    useEffect(() => {
        const onScroll = () => {
            if (!outerRef.current || !titleTopRef.current || window.matchMedia("(max-width: 500px)").matches) {
                return
            }

            const { top, bottom, height } = outerRef.current.getBoundingClientRect()
            const { height: titleHeight } = titleTopRef.current.getBoundingClientRect()
            const innerHeight = window.innerHeight
            const buffer = innerHeight * .65
            const opacity = clamp((-bottom + buffer) / (buffer), 0, 1)
            const y = clamp(-top / (height * .5), 0, 1) * titleHeight * 1.25

            targetOpacity.current = 1 - opacity
            targetY.current = y
        }

        onScroll()

        window.addEventListener("scroll", onScroll)

        return () => {
            window.removeEventListener("scroll", onScroll)
        }
    }, [])

    return (
        <div
            style={{ "--height": height } as React.CSSProperties}
            ref={outerRef}
            className={cn(
                s["hero-parallax-module"],
                s["hero-parallax-module--" + heroStyle],
                {
                    [s["hero-parallax-module--center-extra-large"]]: titleSize === "extraLarge" && titlePosition === "center",
                    [s["hero-parallax-module--left-extra-large"]]: titleSize === "extraLarge" && titlePosition === "left",
                    [s["hero-parallax-module--center-large"]]: titleSize === "large" && titlePosition === "center",
                    [s["hero-parallax-module--left-large"]]: titleSize === "large" && titlePosition === "left",
                },
            )}
        >
            <div
                className={cn(
                    s["hero-parallax-module__wrapper"],
                    s["hero-parallax-module__wrapper--" + heroStyle],
                )}
                ref={overflowRef}
                style={{
                    "--bottom-angle-position": bottomAnglePosition,
                    overflow: heroStyle === "inverted" ? "visible" : undefined,
                    clipPath: heroStyle === "inverted" ? "" : undefined,
                } as React.CSSProperties}
            >
                <div
                    ref={titleTopRef}
                    className={cn(
                        s["hero-parallax-module__title"],
                        s["hero-parallax-module__title--top"],
                        {
                            [s["hero-parallax-module__title--dark"]]: heroStyle === "black",
                            [s["hero-parallax-module__title--white"]]: heroStyle === "white",
                            [s["hero-parallax-module__title--inverted"]]: heroStyle === "inverted",
                            [s["hero-parallax-module__title--centered"]]: titlePosition === "center",
                        },
                    )}
                >
                    <Container>
                        <div
                            style={{
                                maxWidth: "55em",
                                margin: titlePosition === "center" ? "0 auto" : undefined,
                            }}
                        >
                            <Heading
                                className={cn({
                                    "heading-400": titleSize !== "extraLarge",
                                    "heading-500": titleSize === "extraLarge",
                                })}
                            >
                                <RichText blocks={title} />
                            </Heading>
                        </div>
                    </Container>
                </div>

                <div className={cn(s["hero-parallax-module__inner"], `bg-${mediaBackgroundColor}`)}>
                    {media && <FullscreenMedia media={media} />}
                </div>
            </div>
            <div className={s["hero-parallax-module__bottom"]}>
                <Container>
                    <div
                        ref={titleBottomRef}
                        aria-hidden
                        className={cn(
                            s["hero-parallax-module__title"],
                            s["hero-parallax-module__title--bottom"],
                            {
                                [s["hero-parallax-module__title--centered"]]: titlePosition === "center",
                            },
                        )}
                    >
                        <div
                            style={{
                                maxWidth: "55em",
                                margin: titlePosition === "center" ? "0 auto" : undefined,
                            }}
                        >
                            <Heading
                                className={cn({
                                    "heading-400": titleSize !== "extraLarge",
                                    "heading-500": titleSize === "extraLarge",
                                })}
                            >
                                <RichText blocks={title} />
                            </Heading>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}
