import { Section } from "src/utils"
import React from "react"
import { SanityCallToAction, SanityRichText, ThemeColor } from "../../sanity/types"
// import { Container } from "../../layout/container/Container"
import cn from "classnames"
import s from "./HeadlineTextAndCta.module.scss"
import { Container } from "../../layout/container/Container"
import CallToAction from "src/components/call-to-action/CallToAction"
import Icon, { IconType } from "src/components/icon/Icon"
// import RichText from "../../components/rich-text/RichText"
// import Angle from "../../components/angle/Angle"
// import CallToAction from "../../components/call-to-action/CallToAction"
// import Media from "src/components/media/Media"
import RichText from "../../components/rich-text/RichText"
import { colorValueMap } from "src/sanity/colors"
import AppStore from "src/components/app-store/AppStore"
import SendSms from "src/components/send-sms/SendSms"
import { useOverwrites } from "src/layout/page/overwriteContext"
import { useSettings } from "src/layout/page/settingsContext"
import BusinessSignup from "src/components/send-sms/BusinessSignup"
// import ButtonGroup from "../../components/button-group/ButtonGroup"


export interface HeadlineTextAndCtaProps {
    title?: {
        text?: SanityRichText
        size?: string
        lightFontWeight?: boolean
        icon: IconType
        iconColor: ThemeColor
    }
    body: SanityRichText
    cta: SanityCallToAction
    showDownloadFeature: boolean
    centerContent: boolean
    backgroundColor: ThemeColor
}

export default function HeadlineTextAndCta({ title, body, cta, showDownloadFeature, centerContent, backgroundColor }: HeadlineTextAndCtaProps): JSX.Element {
    const { linkAppStore, linkGooglePlay, downloadDetails: downloadOverlayDefault, segment } = useSettings()
    const { downloadOverlay: downloadOverlayOverwrite } = useOverwrites()
    const downloadDetails = { ...downloadOverlayDefault, ...(downloadOverlayOverwrite ?? {}) }
    const moduleContrastColor = ["primary-blue", "black", "paas-blue"].includes(backgroundColor)

    return (
        <Section>
            <div className={cn(s["headline-text-and-cta"], {
                [`bg-${backgroundColor}`]: !!backgroundColor,
                "has-background-color": !!backgroundColor,
                "text-white": moduleContrastColor,
                [s["headline-text-and-cta--center"]]: centerContent,
            })} style={{ "--accent": title?.iconColor ? colorValueMap[title.iconColor] : "inherit" } as React.CSSProperties}>
                <Container className={cn(s["headline-text-and-cta__container"])}>
                    <div className={cn(s["headline-text-and-cta__content"])}>
                        {/* Text */}
                        {title?.text && (
                            <h2 className={cn(s["headline-text-and-cta__title"], title?.size, "balance-text")}>
                                <>
                                    <RichText blocks={title.text} />
                                    {title?.icon && (
                                        <Icon style={{ color: "var(--accent)" }} type={title?.icon} />
                                    )}
                                </>
                            </h2>
                        )}

                        {body && (
                            <>
                                <RichText blocks={body} className={cn(s["headline-text-and-cta__body"], "paragraph-100", "hide-on-desktop")} />
                                <RichText blocks={body} className={cn(s["headline-text-and-cta__body"], "light-font-weight", "hide-on-mobile")} />
                            </>
                        )}

                        {/* CTA */}
                        {cta?.style && (
                            <CallToAction {...cta} className={cn(s["headline-text-and-cta__cta"])} appearance="dark" />
                        )}

                        {/* Download feature */}
                        {showDownloadFeature && (
                            <>
                                {segment === "private" && (
                                    <>
                                        <AppStore
                                            linkAppStore={linkAppStore}
                                            linkGooglePlay={linkGooglePlay}
                                            orientation="horizontal"
                                            onelinkUrl={downloadOverlayOverwrite?.onelink}
                                            className={s["headline-text-and-cta__app-badge"]}
                                        />
                                        <SendSms {...downloadDetails} title={null} subtitle={null} className={s["headline-text-and-cta__send-sms"]} />
                                    </>
                                )}
                                {segment === "business" && (
                                    <BusinessSignup downloadDetails={{ ...downloadDetails, title: null, subtitle: null }} showOnlyform={true} className={s["headline-text-and-cta__business-signup"]} />
                                )}
                            </>
                        )}

                    </div>
                </Container>
            </div>
        </Section>
    )
}
